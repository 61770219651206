<template>
  <v-app-bar class="adv-app-bar" fixed>
    <slot />
    <ul class="adv-app-nav">
      <li
        class="adv-app-nav__links"
        v-for="(item, index) in navItens"
        :key="index"
        :href="item.ref"
        v-smooth-scroll
      >
        {{ item.title }}
      </li>
    </ul>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBarLinks",
  props: {
    navItens: Array,
  },
};
</script>

<style lang="scss">
@import "../styles/Responsive.scss";
.adv-app-bar {
  top: 65px !important;
  .adv-app-nav {
    list-style: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &__links {
      padding: 0 1rem;
      @include breakpoint("md") {
        display: none;
      }
      @include breakpoint("xs") {
        display: none;
      }
      @include breakpoint("sm") {
        display: none;
      }
      @include breakpoint("mg") {
        display: block;
      }
      @include breakpoint("lg") {
        display: block;
      }
    }
  }
}
</style>
