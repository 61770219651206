<template>
  <div class="adv-contact">
    <v-form v-model="valid" ref="form">
      <v-container>
        <v-row>

          <v-col cols="12" md="12">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
             <label>A pessoa está presa? </label>
             <v-radio-group v-model="estaPreso" :rules="commonRules" >
                <v-radio
                  key="0"
                  label="Sim"
                  value="Sim"
                ></v-radio>
                <v-radio
                  key="1"
                  label="Não"
                  value="Não"
                ></v-radio>
             </v-radio-group>
          </v-col>

          <v-col cols="12" md="12">
             <label>Já existe um processo em andamento? </label>
             <v-radio-group v-model="temProcesso" :rules="commonRules">
                <v-radio
                  key="0"
                  label="Sim"
                  value="Sim"
                ></v-radio>
                <v-radio
                  key="1"
                  label="Não"
                  value="Não"
                ></v-radio>
                <v-radio
                  key="1"
                  label="Não sei"
                  value="Não sei"
                ></v-radio>
             </v-radio-group>
          </v-col>

          <v-col cols="12" md="12">
            <label>Qual o crime? </label>
            <v-radio-group v-model="crime" required :rules="commonRules">
              <v-radio
                key="0"
                label="Homicídio"
                value="Homicídio"
              ></v-radio>
              <v-radio
                key="1"
                label="Tráfico de drogas"
                value="Tráfico de drogas"
              ></v-radio>
              <v-radio
                key="2"
                label="Roubo/furto"
                value="Roubo/furto"
              ></v-radio>
              <v-radio
                key="3"
                label="Violência doméstica (Lei Maria da Penha)"
                value="Violência doméstica (Lei Maria da Penha)"
              ></v-radio>
              <v-radio
                key="4"
                label="Lesão corporal"
                value="Lesão corporal"
              ></v-radio>
              <v-radio
                key="5"
                label="Crimes de trânsito (lei seca, atropelamento etc)"
                value="Crimes de trânsito (lei seca, atropelamento etc)"
              ></v-radio>
              <v-radio
                key="6"
                label="Outro"
                value="Outro"
              ></v-radio>
            </v-radio-group>
          </v-col>

          <!-- <v-col cols="12" md="12">
            <v-textarea solo name="textarea" v-model="resumo"
            label="Poderia explicar em poucas palavras
            o que aconteceu?" required :rules="commonRules"></v-textarea>
          </v-col> -->
          <!-- <v-btn :disabled="!valid" class="ml-4" @submit.prevent="submit"> Envar </v-btn> -->

          <v-btn :disabled="!valid" class="ml-4" @click="submit"> Enviar </v-btn>
        </v-row>
      </v-container>
    </v-form>

    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary mb-10" large dark v-bind="attrs" v-on="on">
              contato
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              Contato
            </v-card-title>
            <v-card-text>
              <h3>Sua mensagem foi enviada com sucesso!</h3>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="green darken-2 white--text"
                @click="close"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-row>

  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "Contact",
  data: () => ({
    showDialog: false,
    valid: false,
    estaPreso: "",
    commonRules: [
      (v) => !!v || "Campo obrigatório!!!",
    ],
    crime: "",
    temProcesso: "",
    resumo: "",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail é obrigatório!!!",
      (v) => /.+@.+/.test(v) || "E-mail deve ser válido",
    ],
    nameRules: [
      (v) => !!v || "Nome é obrigatório!!!",
      (v) => v.length <= 3 || "Nome deve conter no minimo 3 caracteres",
    ],
  }),
  methods: {
    close() {
      this.showDialog = false;
      this.$refs.form.resetValidation();
    },
    submit() {
      axios.post("https://testezer-cors-anywhere.herokuapp.com/v2/arthurnevesadv", {
        email: this.email,
        temProcesso: this.temProcesso,
        crime: this.crime,
        estaPreso: this.estaPreso,
      })
        .then(() => {
          this.email = "";
          this.temProcesso = "";
          this.crime = "";
          this.estaPreso = "";
          this.resumo = "";
          this.showDialog = true;
        })
        .catch((error) => { console.log(error); });
    },

  },
};
</script>

<style lang="scss" scoped>
.adv-contact {
  max-width: 500px;
}
</style>
