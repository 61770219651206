<template>
  <div class="section">
    <h1 v-if="title">{{ title }}</h1>

    <div class="box">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Responsive.scss";
.section {
  margin: 7.5rem auto;
  padding: 3rem 5rem;
  background: white;
  max-width: 1080px;
  width: 95%;
  border-radius: 5px;
  > h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  @include breakpoint("xs") {
    padding: 5%;
  }
  @include breakpoint("sm") {
    padding: 5%;
  }
  @include breakpoint("md") {
    padding: 5%;
  }
}
</style>
