import Vue from 'vue';
import VueSmoothScroll from 'vue2-smooth-scroll';
import App from './App.vue';
import vuetify from './plugins/vuetify';

Vue.use(VueSmoothScroll, {
  offset: -125,
});
Vue.config.productionTip = false;

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
