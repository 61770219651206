<template>
  <div class="adv-about">
    <p>
      As Ciências Criminais são encarregadas de cuidar do bem mais valioso na
      vida em sociedade, o direito de viver em liberdade. Por esse motivo, é de suma
      importância que seu tratamento seja realizado com muito empenho.
    </p>
    <p>
      Assim, primamos pela atuação com excelência, paixão e dedicação exclusiva
      aos diversos casos jurídicos, sempre agindo com total discrição e seriedade,
      atributos fundamentais no relacionamento com nossos clientes.
    </p>
    <p>
      Atualmente o escritório é liderado por Arthur Neves, advogado bacharel em
      direito pela Universidade do Estado do Rio de Janeiro (UERJ), pós graduando em
      Direito Processual Penal, além de possuir curso de extensão em Processo Penal
      (Instituto Brasileiro de Ciências Criminais em conjunto com o Instituto de Direito
      Penal Econômico e Europeu da Faculdade de Direito da Universidade de Coimbra).
    </p>
    <!-- <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit,
      itaque. Id veritatis enim eligendi maiores aperiam vel consequuntur
      corporis ut, vero nemo. Harum dignissimos hic totam expedita ullam sunt
      delectus?
    </p> -->

    <!-- <div class="adv-about__box"> -->
      <!-- <strong>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit,
        itaque.
      </strong> -->
      <!-- <strong>
        SDSDS
      </strong> -->
    <!-- </div> -->
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style lang="scss" scoped>
.adv-about {
  text-align: justify;
  &__box {
    border: 2px solid #a0a0a0;
    padding: 2rem;
    text-align: center;
    margin-top: 2rem;
  }
}
</style>
