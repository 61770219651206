<template>

  <div class="home">

    <LinkHeader />

    <NavBarLinks :navItens="navItens">
      <v-app-bar-nav-icon
        class="adv-hamburguer"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </NavBarLinks>

    <v-container>

      <!-- <Section id="home" :style="Style">
        <div class="home-logo"> -->
          <!-- <v-img max-width="350" src="../assets/logoNova.png" alt="ADV" /> -->
        <!-- </div>
      </Section> -->

      <Section id="sobre" title="Sobre">
        <About />
      </Section>

      <Section id="area-de-atuacao" title="Áreas de Atuação">
        <div class="card-box">
          <div class="card-box__info">

            <p>
              Nossa atuação é voltada para todo o ramo do Direito Penal e
              possui ênfase nos seguintes delitos:
            </p>

          </div>

          <div class="card-box__form">

            <Areas />

          </div>

        </div>
      </Section>

      <!-- <Section id="trabalhos-desempenhados" title="Trabalhos Desempenhados">
        <Trabalhos />
      </Section> -->

      <!-- <Section id="boletim-informativo" title="Boletim Informativo">
        <Boletim />
      </Section> -->

      <!-- <iframe href="https://docs.google.com/forms/d/e/1FAIpQLSfIEaLgO3XTX8xqqYCNWgthPC33Wes_HSsef4d2YkmDJQW4dA/formResponse" width="90%" height="500px"></iframe> -->

      <Section id="contato">
        <div class="card-box">

          <div class="card-box__info">
            <h1 class="subtitle mb-10">Bem vindo!</h1>

            <p>
              Neste primeiro momento peço para que você preencha
              este pequeno formulário para que eu possa compreender
              melhor sua situação e oferecer um atendimento direcionado
              à solução de seu problema.
            </p>

            <!-- <p class="text">
              <strong>Fone:</strong> (XX) XXXXX-XXXX
              <v-icon>mdi-whatsapp</v-icon>
            </p> -->

            <!-- <p class="text">
              <strong>E-mail:</strong> contato@contatoadvemail.adv.br
            </p>

            <p class="text">
              <strong>Endereço:</strong>
              <span>
                Rua: XXXXXXXXX, n° XXXXXXX – Bairro, Cidade – Estado, Cep
              </span>
            </p> -->
          </div>

          <div class="card-box__form">
            <Contact />
          </div>

        </div>
      </Section>

    </v-container>

    <v-btn
      href="https://api.whatsapp.com/send?phone=5521994502211"
      fixed
      fab
      large
      dark
      bottom
      left
      target="_blank"
      color="green accent-4"
    >
      <v-icon large>mdi-whatsapp</v-icon>
    </v-btn>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" class="adv-list">
          <li
            class="adv-list-hamburguer"
            v-for="item in navItens"
            :key="item.title"
            :href="item.ref"
            @click.stop="closeModal"
            v-smooth-scroll
          >
            {{ item.title }}
          </li>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import LinkHeader from "../components/LinkHeader.vue";
import NavBarLinks from "../components/NavBarLinks.vue";
import Contact from "../components/Contact.vue";
import About from "../components/AdvAbout.vue";
import Areas from "../components/Areas.vue";
// import Trabalhos from "../components/Trabalhos.vue";
// import Boletim from "../components/Boletim.vue";
import Section from "../components/Container.vue";

export default {
  name: "Home",
  components: {
    LinkHeader,
    NavBarLinks,
    Contact,
    About,
    Areas,
    // Boletim,
    Section,
  },
  data: () => ({
    drawer: false,
    group: null,
    navItens: [
      // {
      //   title: "Home",
      //   ref: "#home",
      // },
      {
        title: "Sobre",
        ref: "#sobre",
      },
      {
        title: "Áreas de Atuação",
        ref: "#area-de-atuacao",
      },
      // {
      //   title: "Trabalhos Desempenhados",
      //   ref: "#trabalhos-desempenhados",
      // },
      // {
      //   title: "Boletim Informativo",
      //   ref: "#boletim-informativo",
      // },
      {
        title: "Contato",
        ref: "#contato",
      },
    ],
    Style: {
      backgroundColor: "#00000000",
    },
  }),
  methods: {
    closeModal() {
      this.drawer = false;
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/Responsive.scss";
.home {
  .card-box {
    display: flex;
    justify-content: center;
    &__info {
      max-width: 400px;
      .text,
      .subtitle {
        text-align: center;
      }
      .text {
        > span {
          word-break: break-word;
          max-width: 328px;
        }
      }
    }
    @include breakpoint("xs") {
      flex-direction: column;
    }
    @include breakpoint("sm") {
      flex-direction: column;
    }
    @include breakpoint("md") {
      flex-direction: column;
    }
    @include breakpoint("mg") {
      flex-direction: row;
    }
  }
  .adv-list {
    margin-top: 65px;
    .adv-list-hamburguer {
      list-style: none;
      padding: 1rem;
      cursor: pointer;
    }
  }

  .adv-hamburguer {
    display: none;
    @include breakpoint("md") {
      display: block;
    }
    @include breakpoint("xs") {
      display: block;
    }
    @include breakpoint("sm") {
      display: block;
    }
    @include breakpoint("mg") {
      display: none;
    }
    @include breakpoint("lg") {
      display: none;
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
