<template>

  <div class="adv-areas">

    <!-- <p>
      Nossa atuação é voltada para todo o ramo do Direito Penal, entretanto,
      possui ênfase nos seguintes delitos:
    </p> -->

    <div class="adv-trabalhos">
      <ul>
      <li v-for="(item, i) in expansion" :key="i">
        {{ item.title }}
      </li>
    </ul>
      <!-- <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in expansion" :key="i">
          <v-expansion-panel-header>
            {{ item.title }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ item.text }}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels> -->
    </div>
  </div>

</template>

<script>
export default {
  name: "Areas",

  data() {
    return {
      expansion: [
        {
          title: "Crimes contra a Vida e Tribunal do Júri",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Crimes contra a Pessoa",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Crimes contra o Patrimônio e a Propriedade",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Crimes da Lei de Trânsito",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Crimes Cibernéticos",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Contravenções Penais",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Violência Doméstica contra a Mulher",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Inquéritos e Investigações Criminais",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Inquéritos Civis",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Execução Penal",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Extradição",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Investigação Criminal Defensiva",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
        {
          title: "Criminal Compliance",
          text: `
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
            `,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

.adv-trabalhos {
  p {
    font-size: 1.2rem;
  }
  ul {
    li {
      padding-bottom: 0.5rem;
    }
  }
}

.adv-areas {
  .v-expansion-panel {
    margin: 1rem 0;
    background-color: #f4f4f4;
    border: 1px solid #d9d9d9;
  }
}

</style>
