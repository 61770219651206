<template>
  <v-app id="adv-site">
    <v-main class="adv-container">
      <Home />
    </v-main>
  </v-app>
</template>

<script>
import Home from "./view/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style lang="scss">
@import "@/styles/Base.scss";
@import "@/styles/Responsive.scss";

</style>
