<template>
  <div class="adv-contato">
    <ul class="adv-contato-list">
      <li class="adv-contato-list__fone">
        <v-icon color="green accent-4">mdi-whatsapp</v-icon> (21) 99450-2211
      </li>
      <li class="adv-contato-list__email">
        <v-icon>mdi-email</v-icon> E-mail: n.arthur@hotmail.com
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LinkHeader",
};
</script>

<style lang="scss" scoped>
@import "../styles/Responsive.scss";
.adv-contato {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 65px;
  position: fixed;
  z-index: 10;
  &-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #000;
    padding: 0.5rem;
    @include breakpoint("mg") {
      ::after {
        content: "|";
        color: #000;
        padding-left: 8px;
        padding-right: 8px;
        font-weight: 400;
      }
      flex-direction: row;
    }
    @include breakpoint("md") {
      flex-direction: column;
    }
    @include breakpoint("xs") {
      flex-direction: column;
    }
    @include breakpoint("sm") {
      flex-direction: column;
    }
    @include breakpoint("lg") {
      flex-direction: row;
    }
    :last-child::after {
      content: "";
    }
    &__fone {
      padding-right: 0.5rem;
    }
  }
}
</style>
